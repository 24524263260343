import React, { useState } from 'react';
import axios from 'axios';

const MessageParser = ({ children, actions,}) => {

  const [alimit,setALimit] = useState(10)
  const [blimit, setBLimit] = useState(1)
  const [question, setQuestion] = useState("");

  const parse = (message) => {
    setQuestion(message);
  }

 async function handleFQA(){

  const rdata = {
    "query":question,
    "topk":parseInt(blimit)
  }
  console.log("blimit is : ")
  console.log(rdata);
  actions.getQnaAction(rdata, alimit);
  // try {
  //   await axios({
  //     method: 'post',
  //     headers: {
  //       'Content-Type': 'application/json',
  //       'x-functions-key': 'SXIjm2GdTGioEQyAuymQ-xu8HJFFxaLghonv_Nrv-60tAzFuqtyvsA==',
  //     },
  //     url: 'https://qandaapi.azurewebsites.net/get_document',
  //     data: JSON.stringify(rdata),
  //   })
  //   .then((res) => {
  //     console.log(blimit)
  //     console.log(res.data.response)
  //   });
  // } catch (e) {
  //   console.log(e);
  // }
}

async function handleGetans(){
  actions.getAnsAction(question);
}

  return (
    <div>
      
      {React.Children.map(children, (child) => {
        return React.cloneElement(child, {
          parse: parse,
          actions,
        });
      })}

    <div className="range">

    <p className='set'>Set Threshold</p>
    <input  type="range" min="0" max="100" step="10" value={alimit} onChange={(e)=>setALimit(e.target.value)} style={{color:"yellow"}}/>
    <p>{alimit}</p>
    <p className='set'>Set Top K Answers</p>
    <input  type="range" min="0" max="10" step="1" value={blimit} onChange={(e)=>setBLimit(e.target.value)} style={{color:"yellow"}}/>
    <p>{blimit}</p>


    <div className='btns'>
      <button className='sbtn' onClick={handleFQA}>Find questions and answers</button>
      <br/>
      <button className='sbtn' onClick={handleGetans}>Get answer and upload it to DB</button>
    </div>

    </div> 

    </div>
  );
};

export default MessageParser;