import { createClientMessage } from 'react-chatbot-kit';
import React from 'react';
import { useState, useEffect } from 'react';
import {auth, db} from '../../firebase'
import axios from 'axios';
import {
  collection,
  doc,
  getDocs,
  query,
  updateDoc,
  where,
} from "firebase/firestore";
const ActionProvider = ({ createChatBotMessage, setState, children }) => {
  
  const [user, setUser] = useState(null);
  const [userId, setUserId] = useState("");
  const [userCredits, setUserCredits] = useState(null);
  const [usermail, setUsermail] = useState(null);
  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(async (currentUser) => {
        if (currentUser) {
            setUser(currentUser);
            setUserId(currentUser.uid);
            setUsermail(currentUser.email);

            const querySnapshotNew = await getDocs(
                query(
                    collection(db, "userdata"),
                    where("email", "==", currentUser.email)
                )
            );

            if (!querySnapshotNew.empty) {
                const userData = querySnapshotNew.docs[0].data();
                setUserCredits(userData.credits);
            }
        } else {
            setUser(null);
            setUserId("");
            setUserCredits(null);
        }
    });

    return () => unsubscribe();
}, []);

  const [isLoading, setIsLoading] = useState(false);
  const [copyText,setCopyText]=useState('')
  const handleCopy=()=>{
      navigator.clipboard.writeText(copyText)
  }

  const [username, setUsername] = useState("free_user");

  const changeCredits = async () => {
    const querySnapshotNew = await getDocs(
        query(collection(db, "userdata"), where("email", "==", user.email))
    );
    const documentRef = doc(db, "userdata", querySnapshotNew.docs[0].id);
    updateDoc(documentRef, {
        credits: userCredits - 1,
    });
    setUserCredits((prev) => prev - 1);
}

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(async (currentUser) => {
      if (currentUser) {
        setUsername(currentUser.displayName.split(' ').join('').toLowerCase())
      }
    });

    return () => {
      unsubscribe();
    };
  }, []);

  // useEffect(() => {
  //   fetch(process.env.REACT_APP_API_URL)
  //     .then((response) => response.json())
  //     .then((data) => {
  //       setBotName(data.bot_first_name + "-" + data.bot_last_name)
  //       setQ1(data.questions.q1);
  //       setQ2(data.questions.q2)
  //     })
  //     .catch((error) => {
  //       console.error("Error fetching bot_name:", error);
  //     });
  // }, []);



  useEffect(() => {
    const fetchData = async () => {
      // setIsLoading(true);
      
      try {
        const response = await fetch("https://q-and-a-api-5wz7dep6ya-uc.a.run.app/get_document", {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            "query": "python",
            "topk": 2
          })
        });

        const data = await response.json();
        console.log("qna")
        console.log(data.success);
      } catch (error) {
        console.error('Error fetching data:', error);
      }

      // setIsLoading(false);
    };

    fetchData();
  }, []);




const getAnsAction = async (question) => {
  if (user) {
    if (userCredits !== null && userCredits !== 0) {
  setIsLoading(true);
  try {
    const response = await fetch('https://q-and-a-api-5wz7dep6ya-uc.a.run.app/get_answer', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({
            query: question
        })
    });

    if (!response.ok) {
        throw new Error('Failed to fetch data');
    }

    const data = await response.json();
    const message = createChatBotMessage(data.success, {
      widget: 'review'
    } )
    setIsLoading(false);
    changeCredits();
    updateState(message);
} catch (error) {
    console.error('Error fetching data:', error);
    setIsLoading(false);
}
}
else{
  const msg = createChatBotMessage("You don't have enough credits",  {
    widget: 'null'
  } );
  updateState(msg);
}
  }
  else{
    const msg = createChatBotMessage("Please sign in",  {
      widget: 'null'
    });
    updateState(msg);
  }
};


  async function getQnaAction(rdata, threshold) {
    if (user) {
    setIsLoading(true);
    try {
      const res = await axios({
        method: "post",
        headers: {
          "Content-Type": "application/json",
          // "x-functions-key": "SXIjm2GdTGioEQyAuymQ-xu8HJFFxaLghonv_Nrv-60tAzFuqtyvsA==",
        },
        url: "https://q-and-a-api-5wz7dep6ya-uc.a.run.app/get_document",
        data: JSON.stringify(rdata),
      });
      setIsLoading(false);

      console.log(res.data.success)
      const message = createChatBotMessage(
      <div>
        
          {Object.entries(res.data.success).map(([key, value]) => (
          value[1] > threshold/100 && (
            <div key={key}>
              <p className='ques'>[Q] {key}</p>
              <br/>
              <p>{value[0]}</p>
            </div>
          )
        ))}
      </div>, {
        widget: 'null'
      });
  
      updateState(message);
    } catch (e) {
      console.log(e);
    } 
  }
    else{
      const msg = createChatBotMessage("Please sign in",  {
        widget: 'null'
      });
      updateState(msg);
    }
  }

  const updateState = (message, checker) => {
    setState((prev) => ({
      ...prev,
      messages: [...prev.messages, message],
      checker,
    })) 
  };

  return (
    <div>
      {React.Children.map(children, (child) => {
        return React.cloneElement(child, {
          actions: {
            handleCopy,
            getAnsAction,
            getQnaAction,
          },
        });
      })}

    {isLoading && (
      <div class="shapes"></div>
    )}

    </div>
  );
};

export default ActionProvider;

